<template>
  <UiDropdown v-if="config?.hasStoreLocations" class="nav-dropdown">
    <UiLabel
      :text="$t('layout.header.storeAndEvents')"
      :icons="{
        left: {
          name: 'location-outlined',
        },
      }"
      class="nav-label body-m"
      color="white"
      @click="loadBookingServicesData"
    />
    <template #content>
      <div class="dropdown-item-container">
        <div v-if="!isDataLoaded" class="loader-container">
          <UiLoader size="40px" width="5px" />
        </div>
        <template v-else>
          <span
            v-for="link in storeAndEventsLinks"
            :key="link.to"
            class="dropdown-item body-m"
          >
            <UiLabel :subtext="link.subtext" :text="link.text" :url="link.to" />
          </span>
        </template>
      </div>
    </template>
  </UiDropdown>
  <UiDropdown v-if="config?.hasOnlineReservationSystem" class="nav-dropdown">
    <UiLabel
      :icons="{
        left: {
          name: 'store-outlined',
        },
      }"
      :text="$t('layout.header.bookBeautyServices')"
      class="nav-label body-m"
      color="white"
      @click="loadBookingServicesData"
    />
    <template #content>
      <div class="dropdown-item-container">
        <div v-if="!isDataLoaded" class="loader-container">
          <UiLoader size="40px" width="5px" />
        </div>
        <template v-else>
          <span
            v-for="link in bookBeautyServicesLinks"
            :key="link.to"
            class="dropdown-item body-m"
          >
            <UiLabel :text="link.text" :url="link.to" />
          </span>
        </template>
      </div>
    </template>
  </UiDropdown>
</template>

<script lang="ts" setup>
import { useQuery } from '@tanstack/vue-query'
import UiDropdown from '@sephora-asia/ui-dropdown'
import UiLabel from '@sephora-asia/ui-label'
import UiLoader from '@sephora-asia/ui-loader'
import type { ConfigDTO } from '~/types/api/bff/web/layout/layout.types'

defineProps<{
  config?: ConfigDTO
}>()

const { t } = useI18n()

const { data: activityGroups, refetch: refetchActivityGroups } = useQuery({
  ...useGetStoreServicesActivityGroupQuery(),
  enabled: false,
})
const { data: pages, refetch: refetchPages } = useQuery({
  ...useGetBookingServicesPageQuery(),
  enabled: false,
})
const { data: activities, refetch: refetchActivities } = useQuery({
  ...useGetStoreServicesActivityQuery({
    query: {
      activity_type: 'beauty class',
    },
  }),
  enabled: false,
})
const isDataLoaded = ref(false)

const filteredActivityGroups = computed(() => {
  return (activityGroups.value ?? []).filter(
    activityGroup =>
      !(
        (activityGroup.attributes.bookingVendor === 'In House' &&
          activityGroup.attributes.cutoverFrom === null &&
          activityGroup.attributes.cutoverTill === null) ||
        activityGroup.attributes.activityType !== 'Service'
      ) && activityGroup.attributes.status === 'published'
  )
})

const storeAndEventsLinks = computed(() => {
  const link = [
    {
      text: t('layout.header.discoverStoreEvents'),
      subtext: t('layout.header.discoverStoreEventsDescription'),
      to: '/store-events',
    },
    {
      text: t('layout.header.findAStore'),
      subtext: t('layout.header.findAStoreDescription'),
      to: '/store-locations',
    },
  ]

  if (activities?.value?.length) {
    link.push({
      text: t('layout.header.beautyClasses'),
      subtext: t('layout.header.beautyClassesDescription'),
      to: '/beauty-classes',
    })
  }

  if (pages?.value?.length) {
    pages.value.forEach(page => {
      link.push({
        text: page.pageTitle,
        subtext: page.dropdownDescription,
        to: `/page/${page.slugUrl}`,
      })
    })
  }
  return link
})

const bookBeautyServicesLinks = computed(() => {
  const link = [
    {
      text: t('layout.header.allBeautyServices'),
      to: '/beauty-services',
    },
  ]

  if (filteredActivityGroups.value.length) {
    filteredActivityGroups.value.forEach(activityGroup => {
      link.push({
        text: activityGroup.attributes.name,
        to: `/beauty-services/${activityGroup.attributes.slugUrl}`,
      })
    })
  }

  return link
})

async function loadBookingServicesData() {
  if (isDataLoaded.value) {
    return
  }

  await Promise.all([
    refetchActivityGroups(),
    refetchPages(),
    refetchActivities(),
  ])

  isDataLoaded.value = true
}
</script>

<style lang="scss" scoped>
.nav-label {
  &:hover {
    cursor: pointer;

    :deep(.icon-container) {
      cursor: pointer;
    }
  }
}

.nav-dropdown {
  .dropdown-item-container {
    display: flex;
    width: 300px;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    .loader-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
      width: 100%;
    }
  }

  :deep(.dropdown-menu) {
    padding-top: 8px;
    right: 0;
  }
}
</style>
