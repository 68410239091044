import { queryOptions } from '@tanstack/vue-query'
import type { NitroFetchOptions, NitroFetchRequest } from 'nitropack'

export const useGetStoreServicesActivityGroupQuery = (
  options?: NitroFetchOptions<NitroFetchRequest>
) => {
  const { $storeServicesActivityGroupsRepository } = useNuxtApp()
  return queryOptions({
    queryKey: ['activity-group', options],
    queryFn: () => $storeServicesActivityGroupsRepository.index(unref(options)),
  })
}
