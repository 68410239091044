<template>
  <div v-if="categoryTrees.length > 0" class="sidebar-item-container">
    <UiLabel
      :text="$t('layout.header.categories')"
      class="sidebar-item headline-l"
    />
    <LayoutHeaderSidebarNavigationCategoryTree
      v-for="tree in categoryTrees"
      :key="tree.to"
      :tree="tree"
    />
  </div>
</template>

<script lang="ts" setup>
import { useQuery } from '@tanstack/vue-query'
import UiLabel from '@sephora-asia/ui-label'
import type { Category } from '~/types/api/bff/web/categories/categories'

interface AnalyticsAttributes {
  'data-analytics-id': string
  'data-analytics-label': string
}

interface CategoryTree {
  text: string
  to: string
  children: CategoryTree[]
  additionalAttrs?: AnalyticsAttributes
}

const { t } = useI18n()

const { data: categories, suspense } = useQuery(
  useGetCategoryQuery({ query: { 'page[size]': 500 } })
)

const categoryTrees = computed(() => {
  const tree: CategoryTree[] = [
    {
      text: t('layout.header.new'),
      to: '/new-arrivals',
      children: [],
      additionalAttrs: formatAnalyticsAttrs('new-arrivals > New'),
    },
    {
      text: t('layout.header.brands'),
      to: '/brands',
      children: [],
      additionalAttrs: formatAnalyticsAttrs('brands > Brands'),
    },
  ]

  if (categories.value) {
    tree.push(...buildCategoryTree(categories.value))
  }

  tree.push({
    text: t('layout.header.sale'),
    to: '/sales',
    children: [],
  })

  return tree
})

function buildCategoryTree(
  categories: Category[],
  parentId: string | null = null
): CategoryTree[] {
  return categories
    .filter(category => category.parentId === parentId)
    .map(category => {
      const children = buildCategoryTree(categories, category.id)
      if (children.length > 0) {
        children.unshift({
          text: t('layout.header.viewAllCategory', {
            category: category.label,
          }),
          to: `/categories/${category.slugUrl}`,
          children: [],
          additionalAttrs: generateAnalyticsAttrsLabel(
            `${category.slugUrl}/${t('layout.header.viewAllCategory', {
              category: category.label,
            })}`
          ),
        })
      }
      return {
        text: category.label,
        to: `/categories/${category.slugUrl}`,
        children,
        additionalAttrs: generateAnalyticsAttrsLabel(category.slugUrl),
      }
    })
}

function formatAnalyticsAttrs(label: string): AnalyticsAttributes {
  return {
    'data-analytics-id': 'side-menu',
    'data-analytics-label': label,
  }
}

function generateAnalyticsAttrsLabel(slugUrl: string): AnalyticsAttributes {
  // convert slug to attribute value
  // L1: makeup => makeup
  // L2: makeup/makeup-remover => makeup > makeup remover
  // L3: makeup/makeup-remover/view all makeup remover => makeup > makeup remover > view all makeup remover
  const categories = slugUrl
    .split('/')
    .map(category => category.replaceAll('-', ' '))
    .join(' > ')
    .toLowerCase()

  return formatAnalyticsAttrs(categories)
}

await suspense()
</script>

<style lang="scss" scoped>
.sidebar-item-container {
  .sidebar-item {
    padding: 12px 20px;
  }
}
</style>
