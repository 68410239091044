<template>
  <div class="search-result-container">
    <div
      v-if="(brands?.length ?? 0 > 0) || (categories?.length ?? 0 > 0)"
      class="wrapper"
    >
      <div v-if="brands?.length ?? 0 > 0" class="brands-result">
        <div class="title">
          <span class="headline-m">{{
            $t('layout.header.search.brands')
          }}</span>
        </div>
        <div class="search-result">
          <UiSmartLink
            v-for="brand in brands"
            :key="brand.id"
            :to="slugUrlWithQuery(brand.slugUrl)"
            class="link"
            @click="onClickSearchedBrand(brand)"
          >
            <UiTextWithHighlight
              :full-text="brand.name[locale]"
              :text-to-highlight="query"
              class="text-with-highlight body-m"
            />
          </UiSmartLink>
        </div>
        <div class="divider-container">
          <UiDivider type="secondary" />
        </div>
      </div>
      <div v-if="categories?.length ?? 0 > 0" class="categories-result">
        <div class="title">
          <span class="headline-m">{{
            $t('layout.header.search.categories')
          }}</span>
        </div>
        <div class="search-result">
          <UiSmartLink
            v-for="category in categories"
            :key="category.id"
            :to="slugUrlWithQuery(category.slugUrl)"
            class="link"
            @click="onClickSearchedCategory(category)"
          >
            <UiTextWithHighlight
              :full-text="category.label[locale]"
              :text-to-highlight="query"
              class="text-with-highlight body-m"
            />
            <p
              v-if="category.parent?.label"
              class="text-with-highlight parent-category-name body-m"
            >
              {{ $t('layout.header.search.in') }}
            </p>
            <UiTextWithHighlight
              v-if="category.parent?.label"
              :full-text="category.parent.label[locale]"
              :text-to-highlight="query"
              class="text-with-highlight parent-category-name body-m"
            />
          </UiSmartLink>
        </div>
        <div class="divider-container">
          <UiDivider type="secondary" />
        </div>
      </div>
    </div>
    <div v-if="variants?.length ?? 0 > 0" class="products-result">
      <div class="title">
        <span class="headline-m">{{
          $t('layout.header.search.products')
        }}</span>
      </div>
      <div class="search-result">
        <div
          v-for="variant in variants"
          :key="variant.id"
          class="horizontal-product-card-container"
          @click="onClickSearchedVariant(variant)"
        >
          <UiHorizontalProductCard
            :brand-name="variant.product.brandName[locale]"
            :image-url="variant.imageUrl"
            :pdp-url="slugUrlWithQuery(variant.product.slugUrl)"
            :price="{ originalPrice: variant.displayPrice }"
            :product-name="variant.product.name[locale]"
          />
        </div>
      </div>
    </div>
    <div
      v-if="
        variants?.length === 0 &&
        brands?.length === 0 &&
        categories?.length === 0
      "
      class="no-results"
    >
      <p class="headline-l">
        {{ $t('layout.header.search.couldNotFindMatch', { query }) }}
      </p>
      <div class="search-tips">
        <p class="body-m">
          {{ $t('layout.header.search.tryTheseSearchTips') }}
        </p>
        <ul class="search-tips-list">
          <li class="body-m">{{ $t('layout.header.search.checkSpelling') }}</li>
          <li class="body-m">
            {{ $t('layout.header.search.useGeneralTerms') }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import UiDivider from '@sephora-asia/ui-divider'
import UiHorizontalProductCard from '@sephora-asia/ui-horizontal-product-card'
import UiTextWithHighlight from '@sephora-asia/ui-text-with-highlight'
import UiSmartLink from '@sephora-asia/ui-smart-link'
import type {
  Variant,
  Category,
  Brand,
} from '~/types/api/bff/web/search/search.types'

const { t, locale } = useI18n()
const { trackEvent } = useGtmTrack()

const props = defineProps<{
  brands?: Brand[]
  categories?: Category[]
  query: string
  variants?: Variant[]
}>()

const headerSearchStore = useHeaderSearchStore()

function slugUrlWithQuery(slugUrl: string): string {
  return `${slugUrl}?q=${encodeURIComponent(props.query)}`
}

function onClickSearchedBrand(brand: Brand) {
  headerSearchStore.saveRecentSearches({
    name: brand.name[unref(locale)],
    slug: slugUrlWithQuery(brand.slugUrl),
    type: 'brands',
  })

  onDataTracking(brand.slugUrl, 'brand')
}

function onClickSearchedCategory(category: Category) {
  const { parent } = category
  const label = parent?.label
    ? `${category.label[unref(locale)]} ${t('layout.header.search.in')} ${parent.label[unref(locale)]}`
    : category.label[unref(locale)]

  headerSearchStore.saveRecentSearches({
    name: label,
    slug: slugUrlWithQuery(category.slugUrl),
    type: 'categories',
  })

  onDataTracking(category.slugUrl, 'category')
}

function onClickSearchedVariant(variant: Variant) {
  headerSearchStore.saveRecentSearches({
    name: `${variant.product.brandName[unref(locale)]} ${variant.product.name[unref(locale)]}`,
    slug: slugUrlWithQuery(variant.product.slugUrl),
    type: 'products',
  })

  onDataTracking(variant.product.slugUrl, 'product')
}

function onDataTracking(slugUrl: string, searchType: string) {
  trackEvent({
    event: 'search_menu_suggested_result_click_ssr',
    params: {
      search_term: props.query,
      search_result_slug: slugUrl,
      search_type: searchType,
    },
  })
}
</script>

<style lang="scss" scoped>
.search-result-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  gap: 24px;
  padding: 24px;
  overflow-y: scroll;

  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  @include media(mobile, tablet) {
    flex-direction: column;
    padding: 16px;
  }

  .divider-container {
    width: 100%;
  }

  .brands-result,
  .categories-result,
  .products-result {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 21.5px;

    .title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .search-result {
      display: flex;
    }
  }

  .brands-result {
    .search-result {
      flex-direction: column;
      gap: 11px;
    }
  }

  .categories-result {
    .search-result {
      flex-direction: column;
      gap: 11px;

      .parent-category-name {
        color: var(--color-base-grey-50);
      }
    }
  }

  .products-result {
    .search-result {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .horizontal-product-card-container {
        display: block;
        width: 100%;
      }
    }
  }

  .no-results {
    display: flex;
    gap: 16px;
    width: 100%;
    flex-direction: column;

    .search-tips-list {
      list-style: disc;
      padding-left: 16px;
    }
  }

  .search-result {
    .link {
      display: block;
      text-decoration: none;
      color: var(--color-base-black);

      & * {
        display: inline;
      }

      &:hover > * {
        cursor: pointer;
        color: var(--color-base-red-100);
      }
    }
  }
}
</style>
