<template>
  <UiDropdown
    v-model="isBagOpened"
    class="bag-nav-dropdown"
    :content-overflow="ContentOverflow.RIGHT"
    boundary="bagRef"
  >
    <div ref="bagRef" class="bag-icon-container" @click="fetchBagData">
      <UiIcon
        class="bag-icon"
        name="bag-outlined"
        size="24px"
        :aria-label="
          $t('layout.header.bag.itemsCountAriaLabel', {
            count: bagData.lineItemsCount,
          })
        "
        :badge-content="bagData.lineItemsCount"
        color-fill="white"
      />

      <UiLabel
        :text="$t('layout.header.bag.label')"
        class="body-m nav-label"
        color="white"
      />
    </div>

    <template #content>
      <div class="bag-dropdown-container">
        <UiLabel
          :text="$t('layout.header.bag.label')"
          class="header headline-l"
          color="black"
        />

        <UiLoader
          v-if="isFetching || isPending"
          size="50px"
          width="7px"
          class="loader"
        />

        <template v-else-if="bagData?.lineItems?.length">
          <div class="bag-line-items" @click.stop>
            <template
              v-for="(lineItem, index) in bagData?.lineItems"
              :key="lineItem.lineItemId"
            >
              <UiBagDropdownProductCard
                v-bind="lineItem"
                :quantity-label="$t('layout.header.bag.quantity')"
                :price="{
                  originalPrice: lineItem.displayPrice,
                }"
                :checkoutable-id="lineItem.productId ?? lineItem.sampleId ?? ''"
                class="line-item"
                @remove-line-item="() => onRemoveLineItem(lineItem)"
              />

              <UiDivider
                v-if="index !== bagData.lineItemsCount - 1"
                type="secondary"
              />
            </template>
          </div>

          <div
            v-if="bagData?.shippingOffers?.length"
            class="shipping-offer-container"
          >
            <UiSmartImage
              :class="`${bagData?.shippingOffers?.[0].isFreeShipping ? '' : 'no-'}free-shipping-icon`"
              :src="
                bagData?.shippingOffers?.[0].isFreeShipping
                  ? freeShippingIcon
                  : noFreeShippingIcon
              "
              :alt="bagData?.shippingOffers?.[0].message"
              aspect-ratio="auto"
            />
            <p class="body-s shipping-message">
              {{ bagData?.shippingOffers?.[0].message }}
            </p>
          </div>

          <div class="bag-footer">
            <div class="subtotal headline-m">
              <span>{{ $t('layout.header.bag.subtotal') }}</span>

              <span>{{ bagData?.displaySubtotal }}</span>
            </div>

            <UiButton
              :text="$t('layout.header.bag.viewBag')"
              :size="BtnSize.LARGE"
              :type="BtnType.PRIMARY"
              url="/cart"
              class="view-bag-button"
            />
          </div>
        </template>
        <CartEmptyLineItems v-else />
      </div>
    </template>
  </UiDropdown>
</template>

<script lang="ts" setup>
import UiBagDropdownProductCard from '@sephora-asia/ui-bag-dropdown-product-card'
import UiButton from '@sephora-asia/ui-button'
import UiDivider from '@sephora-asia/ui-divider'
import UiDropdown from '@sephora-asia/ui-dropdown/UiDropdown.vue'
import UiIcon from '@sephora-asia/ui-icon'
import UiLabel from '@sephora-asia/ui-label'
import UiLoader from '@sephora-asia/ui-loader'
import UiSmartImage from '@sephora-asia/ui-smart-image'
import { useMutation } from '@tanstack/vue-query'
import freeShippingIcon from '~/assets/icons/bag-dropdown-free-shipping-icon.svg'
import noFreeShippingIcon from '~/assets/icons/bag-dropdown-no-free-shipping-icon.svg'

import { ContentOverflow } from '@sephora-asia/ui-dropdown/types'
import { BtnType, BtnSize } from '@sephora-asia/ui-button/types'
import type { BagLineItemDTO } from '~/types/api/bff/web/cart/bag.types'

const isBagOpened = ref(false)

const { trackEvent } = useGtmTrack()

const { currentUser } = await useCurrentUser()
const userId = computed(() => currentUser.value?.id)
const { data: bagData, isFetching, fetchBagData } = await useBag(userId)

useNuxtApp().provide('openBagPopUp', openBagPopUp)

const { mutate: onRemoveLineItemMutate, isPending } = useMutation(
  useRemoveFromBag(userId)
)

function trackRemoveLineItemEvent(lineItem: BagLineItemDTO) {
  trackEvent({
    event: 'Remove from Cart',
    product_id: lineItem.productId,
    product_name: lineItem.productName,
    variant_id: lineItem.variantId,
    brand: lineItem.brandName,
    price: lineItem.displayPrice,
    quantity: lineItem.quantity,
  })
}

function onRemoveLineItem(lineItem: BagLineItemDTO) {
  onRemoveLineItemMutate(lineItem.lineItemId)
  trackRemoveLineItemEvent(lineItem)
}

function openBagPopUp() {
  isBagOpened.value = true
}
</script>

<style lang="scss" scoped>
.bag-icon-container {
  display: flex;
  gap: 4px;
  &:hover {
    cursor: pointer;
  }
  :deep(.icon-container) {
    &:hover {
      cursor: pointer;
    }
  }
}

.bag-dropdown-container {
  display: flex;
  position: relative;
  width: 360px;
  flex-direction: column;
  align-items: stretch;
  text-wrap: wrap;

  .header {
    width: 100%;
    padding: 12px 16px;
    border-bottom: 1px solid var(--color-base-grey-10);
  }

  .loader {
    margin: auto;
  }

  &:has(.loader) {
    height: 300px;
  }

  .bag-line-items {
    display: grid;
    max-height: 360px;
    padding: 16px;
    grid-gap: 16px;
    overflow-y: auto;
  }

  .shipping-offer-container {
    width: 100%;
    display: flex;
    height: 40px;
    padding: 0 24px;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;
    background: var(--color-base-red-10);

    .shipping-message {
      flex: 1 0 0;
    }

    :deep(img) {
      width: 24px;
    }
  }

  .bag-footer {
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    .subtotal {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
    }

    .view-bag-button {
      width: 100%;
    }
  }
}

.bag-nav-dropdown :deep(.dropdown-menu) {
  padding-top: 8px;
}
</style>
